<template>
  <div
    class="flex bg-gray-100 dark:bg-trueGray-800 flex-row-reverse min-h-screen"
  >
    <div class="flex flex-col w-0 flex-1">
      <TheHeader />
      <main class="flex-1 relative focus:outline-none">
        <slot />
      </main>
    </div>

    <div
      v-if="notifications.length"
      class="fixed bottom-0 right-0 z-50 flex flex-col"
    >
      <NotificationsContainer />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNotifications } from '~/stores/notifications'

const { list: notifications } = useNotifications()
</script>
